import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DfAccordionComponent extends Vue {
  @Prop({ type: String, default: "" })
  label!: string;

  @Prop({ type: String, default: "" })
  group!: string;

  @Prop({ type: String, default: "" })
  name!: string;

  @Prop({ type: Boolean, default: true })
  autoClose!: string;

  private active = false;

  created() {
    this.$root.$on("autoClose", (eventData: any) => {
      if (eventData.group === this.group && eventData.name !== this.name) {
        this.active = false;
      }
    });
  }

  private onClick() {
    this.active = !this.active;
    if (this.autoClose) {
      this.$root.$emit("autoClose", { group: this.group, name: this.name });
    }
  }
}
